@import url('BaseStyle.css');

@media (min-width: 769px){

    .item-col{
        z-index: 1;
        margin: 2rem 1rem 0px;
        display: flex;
    }

    .item-container{
        background-color: var(--color1);
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 6vw;
        cursor: pointer;
    }

    .item-container1{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .item-container2{
        display: flex;
    }

    .image-item{
        max-width: 5rem;
        width: 7vw;
        border-radius: 1rem;
    }

    .img-delete{
        width: 2rem;
        cursor: pointer;
    }

    .item-title-container{
        display: flex;
        width: 45vw;
        justify-content: center;
        cursor: pointer;
        align-items: center;
    }

    .item-title{
        font-weight: bold;
        margin: 0;
        font-size: 1.3rem;
        text-align: center;
        cursor: pointer;
    }

}
