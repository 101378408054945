@import url('BaseStyle.css');

@media (max-width: 768px){

    #passwMenu-section{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 8;
    }

    #passwMenu-container{
        background-color: var(--color3);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        border-radius: 1rem;
        overflow-x: hidden;
        max-height: 90vh;
        max-width: 90vw;
    }

    #passwMenu-container1{
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 2rem;
        flex-direction: row;
        align-items: center;
    }

    #passwMenu-container2{
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    #passwMenu-container2-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0.5rem;
    }

    #passwMenu-container3{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 1rem;
    }

    #passwMenu-title{
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
    }

    .passwMenu-passwContainer{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 1rem;
    }

    #passwMenu-image{
        height: 5rem;
        border-radius: 0.5rem;
    }

    .passwMenu-label{
        margin-bottom: 0.5rem;
    }

    #passwMenu-input{
        width: 100%;
        height: 8rem;
        text-align: left;
    }

    .passwMenu-input:active{
        border: 0 transparent;
    }

    .passwMenu-showPassw{
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        margin-left: 1rem;
    }
}
