@import url('BaseStyle.css');

@media (max-width: 768px){

    .item-col{
        z-index: 1;
        margin: 2rem 1rem 0px;
        display: flex;
    }

    .item-container{
        background-color: var(--color1);
        width: 50vw;
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column-reverse;
        height: 10rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .item-container1{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;
    }

    .item-container2{
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .image-item{
        max-width: 5rem;
        width: 25vw;
        border-radius: 1rem;
    }

    .img-delete{
        width: 2rem;
        cursor: pointer;
    }

    .item-title-container{
        display: flex;
        height: 3rem;
        align-items: center;
        width: 80%;
        justify-content: center;
        cursor: pointer;
    }

    .item-title{
        font-weight: bold;
        margin: 0;
        font-size: 1.1rem;
        text-align: center;
        cursor: pointer;
    }

}
