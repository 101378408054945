@import url('BaseStyle.css');

@media (min-width: 769px){

    #backupMenu-section{
        width: 100%;
        display: flex;
        position: fixed;
        align-items: center;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 8;
    }

    #backupMenu-container{
        background-color: var(--color3);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        border-radius: 1rem;
        overflow-x: hidden;
    }

    #backupMenu-container1{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    #backupMenu-title{
        font-size: 1.3rem;
    }

    #backupMenu-container2{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    #backupMenu-item-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .backupMenu-item{
        display: flex;
        width: 20rem;
        height: 4rem;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--color1);
        padding: 0.5rem;
        margin: 0.5rem 0;
        border-radius: 0.5rem;
    }
    
}

