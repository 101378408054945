@import url('BaseStyle.css');

@media (min-width: 769px){

    #nav{
        background-color: var(--color1);
        z-index: 1;
    }

    #nav-logo-container{
        border-radius: 3rem;
        background-color: var(--color4);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin-right: 1rem;
    }

    #nav-logo{
        height: 4rem;
    }

    #nav-title{
        font-size: 2rem;
        font-weight: bold;
    }

    #nav-container1{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 1rem 2rem;
    }

    #nav-container2{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #nav-container3{
        display: flex;
        align-content: flex-end;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    #nav-container4{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    #nav-container5{
        margin-bottom: 0.5rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    #img-exit{
        width: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    #img-search{
        width: 2.5rem;
        margin-left: 0.5rem;
        margin-right: 1rem;
        cursor: pointer;
    }

    #input-search{
        background-color: var(--color4);
        color: var(--color2);
        color: var(--color1)
    }

    #input-search:active{
        border: 0 transparent;
    }


    .botton-icon{
        border-radius: 0.5rem;
        background-color: var(--color1);
        z-index: 1;
    }

    .botton-icon:hover{
        background-color: var(--color3);
    }
}
