@import url('BaseStyle.css');

@media (max-width: 768px){

    #login-page{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #login-logo-container{
        border-radius: 3rem;
        background-color: var(--color4);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }

    #login-logo{
        height: 4rem;
    }

    #login-title{
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 2rem;
    }
}