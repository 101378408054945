@import url('BaseStyle.css');

@media (min-width: 769px){

    #alert-container{
        position: fixed;
        background-color: var(--color3);
        padding: 1rem 2rem 2rem 2rem;
        border-radius: 1rem;
        border: 0.2rem solid var(--color1);
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #alert-container1{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #alert-container2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
    }

    #alert-container3{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
    }

    #alert-title{
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
    }

    #alert-cross{
        width: 2rem;
        cursor: pointer;
    }

    #alert-image{
        height: 7rem;
        margin-right: 2rem;
    }

    #alert-text{
        font-size: 1rem;
        text-align: center;
    }

}